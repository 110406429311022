
import { Component, Watch } from "vue-property-decorator";
import HospitalHeader from "@/components/HospitalHeader.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import Dropdown from "@/components/Dropdown.vue";
import FormGroup from "@/components/FormGroup.vue";
import { CourseData, CourseDay, CourseDetail, CourseGroupInfo, CourseInfo, CourseTime } from "@/@types/course";
import FacilityService from "@/services/facilities";
import FacilityModule from "@/store/facility";
import _ from "lodash";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import { deleteNullProp } from "@/utils/Util";
import moment, { Moment } from "moment";
import Button from "@/components/Button.vue";
import Draggable from "vuedraggable";

@Component({
  name: "CourseSetting",
  components: {
    HospitalHeader,
    ManagementFooter,
    Dropdown,
    Button,
    FormGroup,
    Draggable,
  },
})
export default class CourseSetting extends CheckCommonLogin {
  private saveSuccess = false;
  private loading = false;
  private courseLoading = false;
  private deleteLoading = false;
  private courses: CourseInfo[] = [];
  private courseGroups: CourseGroupInfo[] = [];
  private periodError = false;
  private receptionError = false;
  private courseChangeError = false;
  private courseDeleteError = false;
  private businessError = false;
  private businessValidationError = false;
  private courseErrorMessage = "";
  private resErrorMessage = "";
  private isEdited = false; // コースを変更したかどうかのフラグ
  private duplicatePeriodErrorLabel = false;
  private deleteCourseGroupLength = 0;
  @Watch("editForm", { deep: true })
  private changeIsEdited() {
    this.isEdited = true;
  }

  private toCourseGroupSetting() {
    this.$router.push({ name: "CourseGroupSetting" });
  }

  private get hospitalId() {
    return FacilityModule.hospitalId;
  }
  private updateCourseIndexWithId = {
    index: 0,
    id: "",
  };

  private hasEndDate(detailInfo: any): boolean {
    return !!(detailInfo.period.end_year && detailInfo.period.end_month && detailInfo.period.end_date);
  }

  private editForm: CourseInfo[] = [
    {
      name: "",
      duration_minute: 0,
      available_count: 0,
      sort_index: 0,
      visible: false,
      details: [
        {
          period: {
            end_date: 0,
            start_month: 0,
            end_month: 0,
            end_year: 0,
            start_year: 0,
            start_date: 0,
            is_end_date: false,
          },
          days: [
            {
              available_days: [],
              times: [
                {
                  name: "",
                  on_time: {
                    end_minute: 0,
                    end_hour: 0,
                    start_minute: 0,
                    start_hour: 0,
                  },
                  reception_time: {
                    end_date: 0,
                    start_hour: 0,
                    start_month: 0,
                    end_month: 0,
                    end_hour: 0,
                    start_date: 0,
                  },
                },
              ],
              is_holiday_primary: true,
            },
          ],
        },
      ],
    },
  ];

  private baseInfo: CourseInfo = {
    name: "",
    duration_minute: 60,
    available_count: 1,
    sort_index: this.editForm.length + 1,
    visible: true,
    details: [
      {
        period: {
          end_date: null,
          start_month: new Date().getMonth(),
          end_month: null,
          end_year: null,
          start_year: new Date().getFullYear(),
          start_date: new Date().getDate(),
          is_end_date: true,
        },
        days: [
          {
            available_days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "Holiday"],
            times: [
              {
                name: "診療",
                on_time: {
                  end_minute: 0,
                  end_hour: 17,
                  start_minute: 0,
                  start_hour: 9,
                },
                reception_time: {
                  end_date: 0,
                  start_hour: 9,
                  start_month: 1,
                  end_month: 0,
                  end_hour: 9,
                  start_date: 0,
                },
              },
            ],
            is_holiday_primary: true,
          },
        ],
      },
    ],
  };

  private courseName = "";

  private duration = "";

  private start = {
    year: 0,
    month: 0,
    day: 0,
  };

  private end = {
    year: 0,
    month: 0,
    day: 0,
  };

  private durations = [
    {
      label: "15分単位",
      minute: 15,
    },
    {
      label: "30分単位",
      minute: 30,
    },
    {
      label: "60分単位",
      minute: 60,
    },
    {
      label: "90分単位",
      minute: 90,
    },
  ];

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private showDeleteModal(id: string, index: number) {
    const courseGroup = this.courseGroups.find((courseGroup) => courseGroup.course_ids.includes(id));
    if (!courseGroup) {
      this.showModal(`course-delete${index}`);
      return;
    }

    this.showModal(`course-delete-relation${index}`);
    this.deleteCourseGroupLength = courseGroup.course_ids.length;
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private dateTimeCheck(startAt: Moment, endAt: Moment) {
    return endAt.isSameOrAfter(startAt);
  }

  private async changeCourseIndex(event: any) {
    if (event.moved) {
      this.courseLoading = true;
      await Promise.all(
        this.editForm.map(async (courseDetail, index) => {
          if (courseDetail.id)
            await FacilityService.updateCourse(this.hospitalId, courseDetail.id, { sort_index: index });
        })
      );
      await this.getCourses();
      this.courseLoading = false;
    }
  }

  private receptionValidateCheck(
    courseItemIndex: number,
    detailInfoIndex: number,
    dayIndex: number,
    timeIndex: number
  ) {
    const end_time =
      this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].times[timeIndex].reception_time.end_hour;
    const end_month =
      this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].times[timeIndex].reception_time.end_month;
    const end_date =
      this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].times[timeIndex].reception_time.end_date;
    const start_time =
      this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].times[timeIndex].reception_time.start_hour;
    const start_month =
      this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].times[timeIndex].reception_time
        .start_month;
    const start_date =
      this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].times[timeIndex].reception_time.start_date;
    if (start_month * 31 + start_date < end_month * 31 + end_date) {
      this.receptionError = true;
    } else if (start_month * 31 + start_date === end_month * 31 + end_date && end_time <= start_time) {
      this.receptionError = true;
    } else {
      this.receptionError = false;
    }
  }

  private periodValidationCheck(courseItemIndex: number, detailInfoIndex: number) {
    const end_year = this.editForm[courseItemIndex].details[detailInfoIndex].period.end_year;
    const end_month = this.editForm[courseItemIndex].details[detailInfoIndex].period.end_month;
    const end_date = this.editForm[courseItemIndex].details[detailInfoIndex].period.end_date;
    const start_year = this.editForm[courseItemIndex].details[detailInfoIndex].period.start_year;
    const start_month = this.editForm[courseItemIndex].details[detailInfoIndex].period.start_month;
    const start_date = this.editForm[courseItemIndex].details[detailInfoIndex].period.start_date;
    const formatStart = new Date(`${start_year}/${start_month}/${start_date}`).toISOString();
    // 開始日の有効な日付配列を取得
    const validStartDays = this.startDays(start_year, start_month);
    let validEndDays: any = [];
    // 終了日の有効な日付配列を取得
    if (end_year && end_month) {
      validEndDays = this.startDays(end_year, end_month);
    }

    // 開始日が有効な日付配列に含まれていない場合は1にリセット
    if (!validStartDays.includes(start_date)) {
      this.editForm[courseItemIndex].details[detailInfoIndex].period.start_date = 1;
    }

    // 終了日が有効な日付配列に含まれていない場合は1にリセット
    if (end_date && !validEndDays.includes(end_date)) {
      this.editForm[courseItemIndex].details[detailInfoIndex].period.end_date = 1;
    }
    if (end_year && end_month && end_date) {
      const formatEnd = new Date(`${end_year}/${end_month}/${end_date}`).toISOString();
      if (moment(formatStart).isSameOrBefore(formatEnd)) {
        this.periodError = false;
      } else {
        this.periodError = true;
      }
      this.start.year = start_year;
      this.end.year = end_year;
      this.start.month = start_month;
      this.end.month = end_month;
      this.start.day = start_date;
      this.end.day = end_date;
    } else {
      this.periodError = false;
    }
  }

  private isDisplayPeriodError(courseItemIndex: number, detailInfoIndex: number) {
    const end_year = this.editForm[courseItemIndex].details[detailInfoIndex].period.end_year;
    const end_month = this.editForm[courseItemIndex].details[detailInfoIndex].period.end_month;
    const end_date = this.editForm[courseItemIndex].details[detailInfoIndex].period.end_date;
    const start_year = this.editForm[courseItemIndex].details[detailInfoIndex].period.start_year;
    const start_month = this.editForm[courseItemIndex].details[detailInfoIndex].period.start_month;
    const start_date = this.editForm[courseItemIndex].details[detailInfoIndex].period.start_date;
    const formatStart = new Date(`${start_year}/${start_month}/${start_date}`).toISOString();
    if (end_year && end_month && end_date && start_year && start_month && start_date) {
      const formatEnd = new Date(`${end_year}/${end_month}/${end_date}`).toISOString();
      if (moment(formatStart).isSameOrBefore(formatEnd)) {
        return false;
      } else {
        return true;
      }
    }
  }

  //上記のisDisplayPeriodErrorを参考にコース内で詳細情報が複数あった場合に、期間が重なっていたらtrueを返す
  private isDisplayDuplicatePeriodError(courseItemIndex: number) {
    const details = this.editForm[courseItemIndex].details;
    for (let i = 0; i < details.length; i++) {
      for (let j = i + 1; j < details.length; j++) {
        const start_year = details[i].period.start_year;
        const start_month = details[i].period.start_month;
        const start_date = details[i].period.start_date;
        const end_year = details[i].period.end_year;
        const end_month = details[i].period.end_month;
        const end_date = details[i].period.end_date;
        const start_year2 = details[j].period.start_year;
        const start_month2 = details[j].period.start_month;
        const start_date2 = details[j].period.start_date;
        const end_year2 = details[j].period.end_year;
        const end_month2 = details[j].period.end_month;
        const end_date2 = details[j].period.end_date;

        if (!details[i].period.is_end_date && !details[j].period.is_end_date) {
          const formatStart = new Date(`${start_year}/${start_month}/${start_date}`).toISOString();
          const formatEnd = new Date(`${end_year}/${end_month}/${end_date}`).toISOString();
          const formatStart2 = new Date(`${start_year2}/${start_month2}/${start_date2}`).toISOString();
          const formatEnd2 = new Date(`${end_year2}/${end_month2}/${end_date2}`).toISOString();
          if (moment(formatStart).isSameOrBefore(formatEnd2) && moment(formatEnd).isSameOrAfter(formatStart2)) {
            return true;
          }
        } else if (details[i].period.is_end_date && details[j].period.is_end_date) {
          const formatStart = new Date(`${start_year}/${start_month}/${start_date}`).toISOString();
          const formatStart2 = new Date(`${start_year2}/${start_month2}/${start_date2}`).toISOString();
          if (moment(formatStart).isSameOrBefore(formatStart2)) {
            return true;
          }
        } else if (details[i].period.is_end_date && !details[j].period.is_end_date) {
          const formatStart = new Date(`${start_year}/${start_month}/${start_date}`).toISOString();
          const formatEnd2 = new Date(`${end_year2}/${end_month2}/${end_date2}`).toISOString();
          if (moment(formatStart).isSameOrBefore(formatEnd2)) {
            return true;
          }
        } else if (!details[i].period.is_end_date && details[j].period.is_end_date) {
          const formatEnd = new Date(`${end_year}/${end_month}/${end_date}`).toISOString();
          const formatStart2 = new Date(`${start_year2}/${start_month2}/${start_date2}`).toISOString();
          if (moment(formatEnd).isSameOrAfter(formatStart2)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  private changeEndPeriodCheck(event: boolean, courseItemIndex: number, detailInfoIndex: number) {
    if (event) {
      this.$set(this.editForm[courseItemIndex].details[detailInfoIndex].period, "end_year", 0);
      this.$set(this.editForm[courseItemIndex].details[detailInfoIndex].period, "end_month", 0);
      this.$set(this.editForm[courseItemIndex].details[detailInfoIndex].period, "end_date", 0);
    } else {
      const currentDate = new Date();
      this.$set(this.editForm[courseItemIndex].details[detailInfoIndex].period, "end_year", currentDate.getFullYear());
      this.$set(
        this.editForm[courseItemIndex].details[detailInfoIndex].period,
        "end_month",
        currentDate.getMonth() + 1
      );
      this.$set(this.editForm[courseItemIndex].details[detailInfoIndex].period, "end_date", currentDate.getDate());
    }
    this.periodValidationCheck(courseItemIndex, detailInfoIndex);
  }

  private checkEditFormSetDay(courseItemIndex: number, detailInfoIndex: number, dayIndex: number, value: string) {
    return this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].available_days.includes(value);
  }
  private changeEditFormSetDay(courseItemIndex: number, detailInfoIndex: number, dayIndex: number, value: string) {
    if (this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].available_days.includes(value)) {
      const index =
        this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].available_days.indexOf(value);
      this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].available_days.splice(index, 1);
    } else {
      this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].available_days.push(value);
    }
  }

  private get years() {
    const years = [];
    for (let year = new Date().getFullYear() - 20; year <= new Date().getFullYear() + 20; year++) {
      years.push(year);
    }
    return years;
  }
  private get months() {
    const months = [...Array(12)].map((ele, i) => i + 1);
    return months;
  }

  private startDays(year: number, month: number) {
    let days = [];
    if ((month === 2 && year! % 4 === 0 && year! % 100 !== 0) || (month === 2 && year! % 400 === 0)) {
      days = [...Array(29)].map((ele, i) => i + 1);
    } else if (month === 2) {
      days = [...Array(28)].map((ele, i) => i + 1);
    } else if (month === 4 || month === 6 || month === 9 || month === 11) {
      days = [...Array(30)].map((ele, i) => i + 1);
    } else {
      days = [...Array(31)].map((ele, i) => i + 1);
    }
    return days;
  }

  private setTodayDefaultSetting() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const date = today.getDate();
    this.baseInfo.details[0].period.start_year = year;
    this.baseInfo.details[0].period.start_month = month;
    this.baseInfo.details[0].period.start_date = date;
  }

  private get hours23() {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(i);
    }
    return hours;
  }
  private get hours() {
    let hours = [];
    for (let i = 0; i < 25; i++) {
      hours.push(i);
    }
    return hours;
  }
  private get minutes() {
    let minutes = [];
    for (let i = 0; i < 60; i += 15) {
      minutes.push(i);
    }
    return minutes;
  }

  private sameTreatments = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  private addTimezone(courseItemIndex: number, detailInfoIndex: number, dayIndex: number) {
    this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].times.push(
      _.cloneDeep(this.baseInfo.details[0].days[0].times[0])
    );
  }

  private deleteDay(courseItemIndex: number, detailInfoIndex: number, dayIndex: number) {
    this.editForm[courseItemIndex].details[detailInfoIndex].days.splice(dayIndex, 1);
    for (let i = 0; i < this.editForm[courseItemIndex].details[detailInfoIndex].days.length; i++) {
      this.closeModal(`day-delete${i}`);
    }
  }
  private deleteDetail(courseItemIndex: number, detailInfoIndex: number) {
    this.editForm[courseItemIndex].details.splice(detailInfoIndex, 1);
    this.courses[courseItemIndex].details.splice(detailInfoIndex, 1);
    for (let i = 0; i < this.editForm[courseItemIndex].details.length; i++) {
      this.closeModal(`detail-delete${i}`);
    }
  }
  private deleteTimezone(courseItemIndex: number, detailInfoIndex: number, dayIndex: number, timezoneIndex: number) {
    this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].times.splice(timezoneIndex, 1);
    for (let i = 0; i < this.editForm[courseItemIndex].details[detailInfoIndex].days[dayIndex].times.length; i++) {
      this.closeModal(`timezone-delete${i}`);
    }
  }

  private addDetailInfo(courseItemIndex: number) {
    this.editForm[courseItemIndex].details.push(_.cloneDeep(this.baseInfo.details[0]));
    this.courses[courseItemIndex].details.push(_.cloneDeep(this.baseInfo.details[0]));
  }
  private addDay(courseItemIndex: number, detailInfoIndex: number) {
    let currentDays: string[] = [];
    this.editForm[courseItemIndex].details[detailInfoIndex].days.forEach((day: any) => {
      currentDays = [...currentDays, ...day.available_days];
    });
    this.baseInfo.details[0].days[0].available_days = this.baseInfo.details[0].days[0].available_days.filter(
      (i) => currentDays.indexOf(i) == -1
    );
    this.editForm[courseItemIndex].details[detailInfoIndex].days.push(_.cloneDeep(this.baseInfo.details[0].days[0]));
    this.courses[courseItemIndex].details[detailInfoIndex].days.push(_.cloneDeep(this.baseInfo.details[0].days[0]));
    this.baseInfo.details[0].days[0].available_days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "Holiday"];
  }

  private isDisabledDayButton(courseItemIndex: number, detailInfoIndex: number, dayIndex: number, value: string) {
    // dayIndex以外のdayのavailable_daysにvalueが含まれている場合はtrueを返す
    return this.editForm[courseItemIndex].details[detailInfoIndex].days
      .filter((day, index) => index !== dayIndex)
      .some((day) => day.available_days.includes(value));
  }

  private async getCourses() {
    try {
      const res = await FacilityService.getCourses(this.hospitalId);
      this.courses = res.data.data;
      this.editForm = _.cloneDeep(this.courses);
      this.editForm.forEach((course: CourseInfo) => {
        course.details.forEach((detail: CourseDetail) => {
          if (!detail.period.end_year && !detail.period.end_month && !detail.period.end_date) {
            detail.period.is_end_date = true;
          }
          detail.days.forEach((day: CourseDay) => {
            if (!("is_holiday_primary" in day)) {
              (day as any).is_holiday_primary = true;
            }
          });
        });
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private async addCourse() {
    try {
      this.loading = true;
      const base = _.cloneDeep(this.baseInfo);
      base.name = this.courseName;
      //sort_indexの最大値を取得
      const maxSortIndex = this.editForm.reduce((acc, course) => {
        return course.sort_index > acc ? course.sort_index : acc;
      }, 0);
      base.sort_index = maxSortIndex + 1;
      const createRes = await FacilityService.createCourse(this.hospitalId, base.name);
      const course_id = createRes.data.data[0].id;

      // payloadのis_end_dateを全て削除する
      base.details.forEach((detail: CourseDetail) => {
        if (detail.period.is_end_date) {
          delete detail.period.end_date;
          delete detail.period.end_month;
          delete detail.period.end_year;
        }
        delete detail.period.is_end_date;
      });
      await FacilityService.updateCourse(this.hospitalId, course_id, base);
      this.getCourses();
      this.courseName = "";
      this.closeModal("course-create");
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      throw new Error(error);
    }
  }
  private submit(index: number, course_id: string) {
    this.updateCourseIndexWithId.index = index;
    this.updateCourseIndexWithId.id = course_id;
    this.showModal("course-update");
  }
  private updateCancel() {
    if (this.isEdited) {
      location.reload();
    } else {
      this.closeModal("course-update");
    }
  }
  private async update() {
    try {
      this.loading = true;
      // コースに重複時間ないかチェック
      this.editForm[this.updateCourseIndexWithId.index].details.forEach((courseDetail: CourseDetail) => {
        courseDetail.days.forEach((day: CourseDay) => {
          day.times.forEach((time: CourseTime, index) => {
            const filterDuplicateTime = day.times.filter((courseTime: CourseTime, courseTimeIndex) => {
              const startTimeA = moment().set("hour", time.on_time.start_hour).set("minute", time.on_time.start_minute);
              const endTimeA = moment().set("hour", time.on_time.end_hour).set("minute", time.on_time.end_minute);
              const startTimeB = moment()
                .set("hour", courseTime.on_time.start_hour)
                .set("minute", courseTime.on_time.start_minute);
              const endTimeB = moment()
                .set("hour", courseTime.on_time.end_hour)
                .set("minute", courseTime.on_time.end_minute);
              if (!this.dateTimeCheck(startTimeB, endTimeB)) {
                this.businessValidationError = true;
                this.closeModal("course-update");
                setTimeout(() => {
                  this.businessValidationError = false;
                }, 15000);
                throw new Error("施術終了時間は施術開始時間以降の時間を設定してください");
              }
              return (
                index !== courseTimeIndex &&
                (startTimeB.isBetween(startTimeA, endTimeA) ||
                  startTimeB.isSame(startTimeA) ||
                  startTimeB.isSame(endTimeA) ||
                  endTimeB.isBetween(startTimeA, endTimeA) ||
                  endTimeB.isSame(endTimeA) ||
                  endTimeB.isSame(startTimeA))
              );
            });
            if (filterDuplicateTime.length) {
              this.businessError = true;
              this.closeModal("course-update");
              setTimeout(() => {
                this.businessError = false;
              }, 15000);
              throw new Error("コースの時間が重複しているため保存不可");
            }
          });
        });
      });
      const payload = deleteNullProp(_.cloneDeep(this.editForm[this.updateCourseIndexWithId.index]));
      // payloadのis_end_dateを全て削除する
      payload.details.forEach((detail: CourseDetail) => {
        if (detail.period.is_end_date) {
          delete detail.period.end_date;
          delete detail.period.end_month;
          delete detail.period.end_year;
        }
        delete detail.period.is_end_date;
      });
      await FacilityService.updateCourse(this.hospitalId, this.updateCourseIndexWithId.id, payload);
      await this.getCourses();
      this.loading = false;
      this.courses = _.cloneDeep(this.editForm);
      this.saveSuccess = true;
      this.closeModal(`course-update`);
      setTimeout(() => {
        this.saveSuccess = false;
      }, 3000);
    } catch (error: any) {
      this.loading = false;
      if (!this.businessError && !this.businessValidationError) {
        this.courseChangeError = true;
        this.resErrorMessage = error.response.data.error_msg;
        if (error.response.data.error_msg.includes("data.name must")) {
          this.courseErrorMessage = "コース名を入力してください";
        } else if (error.response.data.error_msg.includes("available_days must")) {
          this.courseErrorMessage = "曜日を選択してください";
        } else {
          this.courseErrorMessage = error.response.data.error_msg;
        }
        setTimeout(() => {
          this.courseChangeError = false;
          this.resErrorMessage = "";
          this.courseErrorMessage = "";
        }, 10000);
        throw new Error(error);
      }
    }
  }

  private async courseDelete(courseData: CourseData) {
    try {
      this.deleteLoading = true;
      await FacilityService.deleteCourse(courseData.facility_id, courseData.id);
      location.reload();
      this.deleteLoading = false;
    } catch (error: any) {
      this.deleteLoading = false;
      this.courseDeleteError = true;
      if (error.response.data.error_msg.includes("reservation.id")) {
        this.courseErrorMessage = "未来の予約が存在するため、削除できません";
      }
      setTimeout(() => {
        this.courseDeleteError = false;
      }, 10000);
      throw new Error(error);
    }
  }
  private async getCourseGroups() {
    try {
      const res = await FacilityService.getCourseGroups(this.hospitalId);
      this.courseGroups = res.data.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private isCourseGroup(courseData: CourseData) {
    return this.courseGroups.some((courseGroup) => courseGroup.course_ids.includes(courseData.id));
  }

  private getCourseGroupName(courseData: CourseData) {
    const courseGroup = this.courseGroups.find((courseGroup) => courseGroup.course_ids.includes(courseData.id));
    return courseGroup ? courseGroup?.name : "";
  }

  private getCourseGroupIndex(courseData: CourseData) {
    return this.courseGroups.findIndex((courseGroup) => courseGroup.course_ids.includes(courseData.id));
  }

  private async mounted() {
    this.courseLoading = true;
    await this.getCourses();
    await this.getCourseGroups();
    this.courseLoading = false;
    this.setTodayDefaultSetting();
  }
}
